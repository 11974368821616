
export default {
	data() {
		return {
			currentlyYear: null,
			lastYear: null,

			featured__list: [
				// {
				// 	title: 'ssss',
				// 	// link:  '/cars/allroad',
				// 	link: { path: '/cars/allroad', query: { fast: true } },
				// 	img: {
				// 		x1: require('~/assets/img/featured/selections/page-banner-buyout@1x.webp'),
				// 		x2: require('~/assets/img/featured/selections/page-banner-buyout@1x-mobile.webp')
				// 	}
				// },
				{
					title: 'ВНЕДОРОЖНИКИ',
					// link:  '/cars/allroad',
					link: { path: '/cars/allroad', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-6@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-6@x4.webp')
					}
				},
				{
					title: 'СЕДАНЫ',
					// link: '/cars/sedan',
					link: { path: '/cars/sedan', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-2@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-2@x4.webp')
					}
				},
				{
					title: 'ДЛЯ ТАКСИ',
					// link: '/cars/for-taxi',
					link: { path: '/cars/for-taxi', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-1@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-1@x4.webp')
					}
				},
				{
					title: 'БИЗНЕС-КЛАСС',
					// link: '/cars/business-class',
					link: { path: '/cars/business-class', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-3@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-3@x3.webp')
					}
				},
				{
					title: 'ДЛЯ НЕЕ',
					// link: '/cars/for-woman',
					link: { path: '/cars/for-woman', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-5@x3.webp'),
						x2: require('~/assets/img/featured/selections/selections-5@x3.webp')
					}
				},
				{
					title: 'СЕМЕЙНЫЕ',
					// link: '/cars/for-family',
					link: { path: '/cars/for-family', query: { fast: true } },
					img: {
						x1: require('~/assets/img/featured/selections/selections-4@x4.webp'),
						x2: require('~/assets/img/featured/selections/selections-4@x4.webp')
					}
				},

			]
		}
	},
	mounted() {
		const sw = new swiper.default('.swiper.swiper--featured__list', {
			modules: [swiper.Navigation, swiper.Autoplay, swiper.Pagination],
			loop: true,
			// autoplayDisableOnInteraction: true,
			autoplay: false,
			// loop:true,
			initialSlide: 0,
			// centeredSlides: true,
			watchSlidesProgress: true,
			// centeredSlidesBounds:true,
			// roundLengths: true, 
			pagination: {
				clickable: true,
				el: ".swiper-pagination.swiper-pagination--featured__list",
				type: "bullets",
			},
			// on:{
			// 	init:()=>alert('smkdnfsbasddkhfbahbs')
			// },
			// centerInsufficientSlides:true,
			// slidesOffsetAfter: this.$refs.asdd.getBoundingClientRect().left,
			// cssMode:true,
			// slidesOffsetBefore: leftPadding,
			// slidesOffsetAfter: leftPadding,
			slidesPerView: 1.3,
			spaceBetween: 50,
			// grid: {
			// 	fill:'rows',
			// 	rows: 2,

			// },
			breakpoints: {
				// when window width is >= 320px
				// 220: {
				// 	slidesPerView: 1.25,
				// 	spaceBetween: 20
				// },
				600: {
					slidesPerView: 3,
					spaceBetween: 90,

				}

			},

			navigation: {
				nextEl: '.swiper-button.swiper-button-next--featured__list',
				prevEl: '.swiper-button.swiper-button-prev--featured__list',
			},
		})
	}
}
